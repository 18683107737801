import { Box, Card, Typography } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import { IGatsbyImageData } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser";
import React from "react";

interface LandingSection2Props {
  hubImage: IGatsbyImageData;
  bridgeImage: IGatsbyImageData;
}

const Organizations: React.FC<LandingSection2Props> = ({ hubImage, bridgeImage }) => {
  return (
    <Card
      sx={{
        position: "relative",
        p: "1.5rem 1.5rem 2rem",
        bgcolor: "darkBackground.default",
        zIndex: 1,
        mt: {
          xs: -6,
          sm: -5
        },
        boxShadow: {
          xs: "0px 2px 15px rgba(0, 0, 0, 0.16)",
          sm: "none"
        }
      }}
    >
      <Typography component="h2" variant="h4" color="primary" textAlign="center" mb={4}>
        Organizations That Trust Us
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          maxWidth: "400px",
          mx: "auto",
          ".img": {
            width: "120px",
            ":not(:last-child)": {
              mr: 4
            }
          }
        }}
      >
        <GatsbyImage className="img" image={hubImage} alt="Bridge" loading="eager" />
        <GatsbyImage className="img" image={bridgeImage} alt="Hub" loading="eager" />
      </Box>
    </Card>
  );
};

export default Organizations;
