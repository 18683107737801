import { Container, GlobalStyles } from "@mui/material";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Benefits from "../components/public/landing/Benefits";
import ForCustomers from "../components/public/landing/ForCustomers";
import Hero from "../components/public/landing/Hero";
import HiwInterviews from "../components/public/landing/HiwInterviews";
import HiwMatching from "../components/public/landing/HiwMatching";
import HiwQuestionnaire from "../components/public/landing/HiwQuestionnaire";
import Organizations from "../components/public/landing/Organizations";
import PlaceHiresExperience from "../components/public/landing/PlaceHiresExperience";
import PublicLayout from "../layout/PublicLayout";

const HomePage: React.FC<any> = ({ data }) => {
  const {
    mdx: {
      fields: {
        frontmatter: {
          seoTitle,
          mastheadTitle,
          mastheadContent,
          experienceContent,
          benefit1Icon,
          benefit2Icon,
          benefit3Icon,
          benefit1Title,
          benefit1Content,
          benefit2Title,
          benefit2Content,
          benefit3Title,
          benefit3Content,
          step1Title,
          step2Title,
          step3Title,
          step1Content,
          step2Content,
          step3Content,
          forRestaurantsTitle,
          forApplicantsTitle
        }
      }
    },
    image1,
    restaurants,
    applicants,
    theHub,
    theBridge
  } = data;

  const headerImage = getImage(image1);
  const restaurantsImage = getImage(restaurants);
  const applicantsImage = getImage(applicants);
  const bridgeImage = getImage(theBridge);
  const hubImage = getImage(theHub);
  const benefits = [
    {
      icon: benefit1Icon,
      title: benefit1Title,
      description: benefit1Content
    },
    {
      icon: benefit2Icon,
      title: benefit2Title,
      description: benefit2Content
    },
    {
      icon: benefit3Icon,
      title: benefit3Title,
      description: benefit3Content
    }
  ];
  const linkList = [
    {
      image: restaurantsImage,
      title: forRestaurantsTitle,
      buttonText: "For Restaurants",
      to: "/restaurants"
    },
    {
      image: applicantsImage,
      title: forApplicantsTitle,
      buttonText: "For Applicants",
      to: "/applicants",
      imageStyle: { objectPosition: "center top" }
    }
  ];
  return (
    <PublicLayout seoTitle={seoTitle}>
      {/* Workaround for 100vw problem on smaller devices */}
      <GlobalStyles styles={`html { overflow-x: hidden; }`} />
      <Hero image={headerImage} title={mastheadTitle} description={mastheadContent} />
      <Container>
        <Organizations bridgeImage={bridgeImage} hubImage={hubImage} />
        <PlaceHiresExperience experienceContent={experienceContent} />
      </Container>
      <HiwQuestionnaire title={step1Title} description={step1Content} />
      <HiwMatching title={step2Title} description={step2Content} />
      <HiwInterviews title={step3Title} description={step3Content} />
      <Container>
        <Benefits benefits={benefits} />
      </Container>
      <ForCustomers data={linkList} />
    </PublicLayout>
  );
};

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        frontmatter {
          seoTitle
          mastheadTitle
          mastheadContent
          experienceContent
          benefit1Icon
          benefit1Title
          benefit1Content
          benefit2Icon
          benefit2Title
          benefit2Content
          benefit3Icon
          benefit3Title
          benefit3Content
          step1Title
          step2Title
          step3Title
          step1Content
          step2Content
          step3Content
          forRestaurantsTitle
          forApplicantsTitle
        }
      }
    }

    image1: file(relativePath: { eq: "home1.png" }) {
      ...fluidImage
    }

    restaurants: file(relativePath: { eq: "restaurantsIntro.png" }) {
      ...fluidImage
    }

    applicants: file(relativePath: { eq: "applicantsIntro.jpg" }) {
      ...fluidImage
    }

    theHub: file(relativePath: { eq: "theHub.png" }) {
      ...fluidImage
    }

    theBridge: file(relativePath: { eq: "theBridge.png" }) {
      ...fluidImage
    }
  }
`;

export default HomePage;
