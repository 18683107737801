import { Button, Container, Tab, Tabs, Typography } from "@mui/material";
import { Link } from "gatsby";
import React, { useState } from "react";
import IntroSection from "../IntroSection";

interface LandingSection8Props {
  data: Array<{
    image: any;
    title: string;
    buttonText: string;
    to: string;
  }>;
}

const ForCustomers: React.FC<LandingSection8Props> = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const currentTab = data[selectedIndex];

  const handleTabChange = (_e: any, value: number) => {
    setSelectedIndex(value);
  };

  return (
    <Container sx={{ mb: "5rem" }}>
      <Tabs
        value={selectedIndex}
        TabIndicatorProps={{ style: { height: 3 } }}
        indicatorColor="primary"
        sx={{ mb: 3 }}
        onChange={handleTabChange}
      >
        <Tab label="For Restaurants" value={0} />
        <Tab label="For Applicants" value={1} />
      </Tabs>

      <IntroSection
        image={currentTab.image}
        title={currentTab.title}
        imageOverlay
        imageSx={{
          borderRadius: "5px",
          overflow: "hidden"
        }}
        containerSx={{ height: 511, color: "primary.contrastText" }}
      >
        <Typography component="h2" variant="h3" align="center" py={2} mb={2}>
          {currentTab.title}
        </Typography>
        <Button
          {...{
            component: Link,
            to: currentTab.to
          }}
          variant="contained"
          color="primary"
          size="large"
          sx={{ mx: "auto" }}
        >
          {currentTab.buttonText}
        </Button>
      </IntroSection>
    </Container>
  );
};

export default ForCustomers;
