import { Avatar, Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import avatar1 from "../../../assets/homeA1.png";
import avatar2 from "../../../assets/homeA2.png";
import avatar3 from "../../../assets/homeA3.png";
import avatar4 from "../../../assets/homeA4.png";
import HalfHalfInfoSection from "../HalfHalfInfoSection";

interface LandingSection3Props {
  experienceContent: string;
}

const PlaceHiresExperience: React.FC<LandingSection3Props> = ({ experienceContent }) => {
  return (
    <HalfHalfInfoSection header="The PlaceHires Experience" content={experienceContent}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mx: "auto",
          ".topAbstract": {
            transform: "rotate(-170deg)",
            marginLeft: "-200px",
            marginBottom: -4,
            objectPosition: "contain !important"
          },
          ".bottomAbstract": {
            transform: "rotate(10deg)",
            marginRight: "-200px",
            marginTop: -4,
            objectPosition: "contain !important"
          }
        }}
      >
        <StaticImage
          className="topAbstract"
          width={120}
          src="../../../assets/abstract1.png"
          alt=""
        />
        <Box
          sx={{
            display: "grid",
            gap: 3,
            gridTemplateColumns: "64px 64px"
          }}
        >
          {[avatar1, avatar2, avatar3, avatar4].map((src, index) => (
            <Avatar sx={{ height: 64, width: 64 }} src={src} key={index} alt="" />
          ))}
        </Box>
        <StaticImage
          className="bottomAbstract"
          width={120}
          src="../../../assets/abstract1.png"
          alt=""
        />
      </Box>
    </HalfHalfInfoSection>
  );
};

export default PlaceHiresExperience;
