import { Button, Container, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { Link } from "gatsby";
import React from "react";
import { FULL_SCREEN_WIDTH, noBackgroundWrapper } from "../../constants/styles";
import InfoHeader from "./InfoHeader";

export type InfoSectionProps = {
  minHeight?: number;
  header?: string | React.ReactNode;
  content?: string;
  children?: React.ReactNode;
  middleHeader?: boolean;
  buttons?: Array<{
    buttonText: string;
    to?: string;
    externalLink?: "new-window" | "same-window";
  }>;
  backgroundColor?: string;
  noPadding?: boolean;
};

const InfoSection: React.FC<InfoSectionProps> = ({
  children,
  minHeight,
  header,
  middleHeader,
  buttons,
  backgroundColor,
  content,
  noPadding
}) => {
  const { classes } = useStyles({
    header,
    minHeight,
    backgroundColor,
    noPadding
  });
  const body = (
    <>
      {header && <InfoHeader header={header} center={middleHeader} />}
      <div className={classes.body}>
        {content && <Typography>{content.replace(/(\\n)/g, "\n")}</Typography>}
        {children}
        {buttons && (
          <div className={classes.actionButton}>
            {buttons.map(({ buttonText, to, externalLink }, index) => (
              <Button
                key={index}
                {...(externalLink
                  ? {
                      component: "a",
                      href: to,
                      target: externalLink === "new-window" && "_blank"
                    }
                  : {
                      component: Link,
                      to
                    })}
                variant="contained"
                color="primary"
                size="large"
              >
                {buttonText}
              </Button>
            ))}
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className={classes.wrapper}>
      {backgroundColor ? (
        <>
          <Container component="div" maxWidth="lg">
            {body}
          </Container>
        </>
      ) : (
        body
      )}
    </div>
  );
};

const useStyles = makeStyles<Partial<InfoSectionProps>>()((theme, props) => ({
  wrapper: {
    ...noBackgroundWrapper(props.minHeight, props.noPadding),
    ...(props.backgroundColor && {
      ...FULL_SCREEN_WIDTH,
      backgroundColor: props.backgroundColor
    })
  },
  body: {
    whiteSpace: "pre-line"
  },
  actionButton: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-around"
  }
}));

export default InfoSection;
