import { Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";

export type InfoHeaderProps = {
  header: string | React.ReactNode;
  maxWidth?: number;
  center?: boolean;
};

const InfoHeader: React.FC<InfoHeaderProps> = ({ header, maxWidth = 655, center }) => {
  const { classes } = useStyles({ maxWidth, center });
  const align = center ? "center" : "inherit";

  return (
    <div>
      <Typography
        variant="h3"
        component="h2"
        color="primary"
        className={classes.header}
        align={align}
        mb={4}
      >
        {header}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles<Partial<InfoHeaderProps>>()((theme, props) => ({
  header: {
    maxWidth: props.maxWidth,
    ...(props.center && {
      marginRight: "auto",
      marginLeft: "auto"
    })
  }
}));

export default InfoHeader;
