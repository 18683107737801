export const hiwBoxWrapper = {
  mb: { xs: 8, sm: 14 }
};

export const hiwBox = {
  position: "relative",
  display: "grid",
  alignItems: "center",
  ".img": {
    mt: 2,
    mb: {
      sm: -8,
      xs: 0
    }
  },
  ".innerImage": {
    objectFit: "contain !important"
  }
} as const;

export const hiwBoxPartialBackground = (side: "left" | "right") =>
  ({
    gridTemplateColumns: {
      md: "1fr 2fr 200px",
      xs: "1fr 2fr 50px"
    },
    ":after": {
      position: "absolute",
      content: "''",
      display: "block",
      top: 0,
      bottom: 0,
      [side]: "calc(-1 * (100vw - 100%))",
      [side === "right" ? "left" : "right"]: {
        md: 200,
        sm: 80,
        xs: 50
      },
      borderRadius: side === "left" ? "0 7px 7px 0" : "7px 0 0 7px",
      bgcolor: "darkBackground.default",
      zIndex: -1
    }
  } as const);

export const hiwAvatarNumber = {
  color: "text.primary",
  border: "1px solid",
  borderColor: "text.primary",
  bgcolor: "transparent",
  height: {
    xs: 36,
    sm: 48,
    md: 84
  },
  width: {
    xs: 36,
    sm: 48,
    md: 84
  },
  fontSize: {
    xs: 14,
    sm: 20,
    md: 32
  }
};
