import { Avatar, Box, Container, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import {
  hiwAvatarNumber,
  hiwBox,
  hiwBoxPartialBackground,
  hiwBoxWrapper
} from "../../../constants/styles/landing";
import InfoHeader from "../InfoHeader";

interface LandingSection5Props {
  title: string;
  description: string;
}

const HiwQuestionnaire: React.FC<LandingSection5Props> = ({ title, description }) => {
  return (
    <Container sx={hiwBoxWrapper}>
      <InfoHeader header="How It Works" center />
      <Box
        sx={{
          ...hiwBox,
          ...hiwBoxPartialBackground("left")
        }}
      >
        <StaticImage
          className="img"
          imgClassName="innerImage"
          src="../../../assets/questionnaire.png"
          alt=""
        />
        <Box
          sx={{
            p: 2,
            maxWidth: 310
          }}
        >
          <Typography component="h3" variant="h4" color="primary" mb={2}>
            {title}
          </Typography>
          <Typography paragraph mb={0}>
            {description}
          </Typography>
        </Box>
        <Avatar
          sx={{
            ...hiwAvatarNumber,
            justifySelf: "flex-end"
          }}
        >
          01
        </Avatar>
      </Box>
    </Container>
  );
};

export default HiwQuestionnaire;
