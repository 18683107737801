import { Avatar, Box, Container, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { hiwAvatarNumber, hiwBox, hiwBoxWrapper } from "../../../constants/styles/landing";

interface LandingSection6Props {
  title: string;
  description: string;
}

const HiwMatching: React.FC<LandingSection6Props> = ({ title, description }) => {
  return (
    <Box sx={{ bgcolor: "darkBackground.default", ...hiwBoxWrapper }}>
      <Container>
        <Box
          sx={{
            ...hiwBox,
            gridTemplateColumns: {
              md: "200px 2fr 1fr",
              xs: "50px 2fr 1fr"
            }
          }}
        >
          <Avatar
            sx={{
              ...hiwAvatarNumber,
              justifySelf: "flex-start"
            }}
          >
            02
          </Avatar>
          <Box
            sx={{
              padding: {
                xs: "1rem 0.5rem 1rem",
                sm: 2
              },
              maxWidth: 310
            }}
          >
            <Typography variant="h4" color="primary" mb={2}>
              {title}
            </Typography>
            <Typography paragraph mb={0}>
              {description}
            </Typography>
          </Box>
          <StaticImage
            className="img"
            imgClassName="innerImage"
            src="../../../assets/matching.png"
            alt=""
          />
        </Box>
      </Container>
    </Box>
  );
};

export default HiwMatching;
