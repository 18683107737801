import { Box, Typography } from "@mui/material";
import { ExtendedButton } from "@placehires/react-component-library";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import IntroSection from "../IntroSection";

interface HeroProps {
  image: IGatsbyImageData;
  title: string;
  description: string;
}

const Hero: React.FC<HeroProps> = ({ image, title, description }) => {
  return (
    <IntroSection
      image={image}
      title={title}
      imageSx={{
        img: {
          objectPosition: {
            xs: "67% 0px",
            md: "unset"
          }
        }
      }}
    >
      <Box
        sx={{
          maxWidth: 440,
          textAlign: {
            xs: "center",
            sm: "left"
          },
          alignSelf: {
            xs: "center",
            sm: "unset"
          }
        }}
      >
        <Typography variant="h2" component="h1" mb={2}>
          {title}
        </Typography>
        <Typography
          sx={{
            mb: 3
          }}
        >
          {description}
        </Typography>
        <ExtendedButton href="/signup/" variant="contained" color="primary" size="large">
          Sign Up
        </ExtendedButton>
      </Box>
    </IntroSection>
  );
};

export default Hero;
