import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { primaryBoxBorder } from "../../../constants/styles";
import InfoHeader from "../InfoHeader";

interface BoxContentProps {
  benefits: Array<{
    icon: any;
    title: string;
    description: string;
  }>;
}

const Benefits: React.FC<BoxContentProps> = ({ benefits }) => {
  const cardList = benefits.map((item) => (
    <BoxContent
      iconSrc={item.icon}
      header={item.title}
      content={item.description}
      key={item.title}
    />
  ));

  return (
    <Box mb="5rem">
      <InfoHeader header="Benefits of PlaceHires Experience" center />
      <Grid container spacing={4}>
        {cardList}
      </Grid>
    </Box>
  );
};

const BoxContent: React.FC<any> = ({ iconSrc, header, content }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          img: {
            height: 35,
            width: 35
          }
        }
      }}
    >
      <Card
        sx={(theme) => ({
          p: 3,
          ...primaryBoxBorder(theme)
        })}
        elevation={0}
      >
        <img src={iconSrc} alt="icon" width={48} height={48} />
        <Typography component="h3" variant="h4" color="primary" mb={3}>
          {header}
        </Typography>
        <Typography paragraph mb={0}>
          {content}
        </Typography>
      </Card>
    </Grid>
  );
};

export default Benefits;
