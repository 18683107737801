import { Avatar, Box, Container, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import {
  hiwAvatarNumber,
  hiwBox,
  hiwBoxPartialBackground,
  hiwBoxWrapper
} from "../../../constants/styles/landing";

interface HiwInterviewsProps {
  title: string;
  description: string;
}

const HiwInterviews: React.FC<HiwInterviewsProps> = ({ title, description }) => {
  return (
    <Container sx={hiwBoxWrapper}>
      <Box
        sx={{
          ...hiwBox,
          ...hiwBoxPartialBackground("right")
        }}
      >
        <StaticImage
          className="img"
          imgClassName="innerImage"
          src="../../../assets/interviews.png"
          alt=""
        />
        <Box
          sx={{
            padding: {
              xs: "1rem 0.5rem 1rem",
              sm: 2
            },
            maxWidth: 310
          }}
        >
          <Typography variant="h4" color="primary" mb={2}>
            {title}
          </Typography>
          <Typography paragraph mb={0}>
            {description}
          </Typography>
        </Box>
        <Avatar
          sx={{
            ...hiwAvatarNumber,
            justifySelf: "flex-end"
          }}
        >
          03
        </Avatar>
      </Box>
    </Container>
  );
};

export default HiwInterviews;
